































import {
	BButton,
	BCard,
	BCardTitle,
	BFormGroup,
	BFormInput,
	BFormInvalidFeedback,
	BFormText,
	BOverlay,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import userModel from "@/api/user.model";
import { AlertKind, showAlert } from "../../helpers";

@Component({
	components: { BButton, BCard, BCardTitle, BFormGroup, BFormInput, BFormText, BFormInvalidFeedback, BOverlay },
})
export default class AdminUpdatePassword extends Vue {
	model: any = userModel;
	isLoading = false;

	currentPassword = "";
	password = "";
	passwordConfirmation = "";

	get isFormValid() {
		return (
			this.currentPassword.length > 0 && this.password.length >= 6 && this.password === this.passwordConfirmation
		);
	}

	async updatePassword() {
		this.isLoading = true;
		try {
			await this.model.updatePassword(this.currentPassword, this.password);
			showAlert(AlertKind.SUCCESS, "A senha foi atualizada com sucesso");
			this.currentPassword = "";
			this.password = "";
			this.passwordConfirmation = "";
		} catch (error: any) {
			console.error(error);
			showAlert(AlertKind.ERROR, error.message ?? "Ocorreu um erro. Tente novamente.");
		}
		this.isLoading = false;
	}
}
